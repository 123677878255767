<template>
  <div>
    <b-sidebar
      v-model="showModal"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-customer-List-title"
      @hidden="hide()"
      no-header
      no-close-on-backdrop
    >
      <template>
        <div class="sidebar-content" id="sidebar-customer-List">
          <div class="filter">
            <div class="d-flex justify-content-between align-items-center p-3">
              <label class="header-text">เพิ่มคูปองและส่วนลดท้ายบิล</label>
              <div class="pointer" @click="hide()">
                <b-icon icon="x-circle-fill"></b-icon>
              </div>
            </div>

            <div>
              <b-tabs v-model="indexEdit" fill>
                <b-tab>
                  <template #title>
                    <div class="text-m-14">โปรโมชั่น</div>
                    <div class="text-m-14">
                      {{ `(${promotionList.length})` }}
                    </div>
                  </template>
                </b-tab>
                <b-tab v-if="!isCampaign">
                  <template #title>
                    <div class="text-m-14">ส่วนลดรายการ</div>
                    <div class="text-m-14">
                      {{ `(${itemDiscountList.length})` }}
                    </div>
                  </template>
                </b-tab>
                <b-tab v-if="isCampaign">
                  <template #title>
                    <div class="text-m-14">ส่วนลดท้ายบิล</div>
                    <div class="text-m-14">
                      {{ `(${items.length})` }}
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
            </div>

            <div
              class="d-flex justify-content-start align-items-end px-3 py-2 filter"
            >
              <InputText
                v-model="filter.search"
                textFloat=""
                type="text"
                name="search_customer"
                faIcon="search"
                className="mb-2"
                @onEnter="handleSearch"
                @onClickIcon="handleSearch"
              />
              <div>
                <img
                  src="@/assets/images/icons/bin.png"
                  alt="delete"
                  class="pointer delete-icon"
                  center
                  width="32"
                  @click="clearSearch()"
                />
              </div>
            </div>
          </div>

          <div class="customer-list pb-3">
            <template v-if="indexEdit == 0">
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in displayPromotionList"
                :key="index"
                @click="selectRow('addSapDiscount', promotion)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="text-m-16">{{ promotion.name }}</p>
                    <p v-if="promotion.code" class="text-m-14">
                      {{ promotion.code }}
                    </p>
                  </div>
                  <div class="flex-gap-1">
                    <span
                      v-if="
                        promotion.point >= 0 && promotion.promotion_value == 0
                      "
                      class="text-m-16"
                    >
                      ได้รับ {{ promotion.point | numeral('0,0') }}</span
                    >
                    <span
                      v-else-if="promotion.custom_header_discount"
                      class="text-m-16 text-danger"
                    >
                      {{ promotion.custom_header_discount > 0 ? '-' : ''
                      }}{{
                        promotion.custom_header_discount | numeral('0,0.00')
                      }}</span
                    >

                    <span v-else class="text-m-16 text-danger"
                      >{{ promotion.promotion_value > 0 ? '-' : ''
                      }}{{
                        `${promotion.promotion_value}` | numeral('0,0.00')
                      }}</span
                    >

                    <span
                      class="text-m-16"
                      :class="{
                        'text-danger':
                          !(
                            promotion.point >= 0 &&
                            promotion.promotion_value == 0
                          ) &&
                          promotion.discount_type !== 'point' &&
                          promotion.promotion_discount_type_id !== 3
                      }"
                    >
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? 'แต้ม'
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == 'percent'
                          ? '%'
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == 'amount'
                          ? 'บาท'
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == 'point'
                          ? 'แต้ม'
                          : ''
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="!isCampaign && indexEdit == 1">
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in displayItemDiscountList"
                :key="index"
                @click="selectRow('addItemDiscount', promotion)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="text-m-16">{{ promotion.name }}</p>
                    <p v-if="promotion.code" class="text-m-14">
                      {{ promotion.code }}
                    </p>
                  </div>
                  <div class="flex-gap-1">
                    <span
                      v-if="
                        promotion.point >= 0 && promotion.promotion_value == 0
                      "
                      class="text-m-16"
                    >
                      ได้รับ {{ promotion.point | numeral('0,0') }}</span
                    >
                    <span
                      v-else-if="promotion.custom_header_discount"
                      class="text-m-16 text-danger"
                    >
                      {{ promotion.custom_header_discount > 0 ? '-' : ''
                      }}{{
                        promotion.custom_header_discount | numeral('0,0.00')
                      }}</span
                    >

                    <span v-else class="text-m-16 text-danger"
                      >{{ promotion.promotion_value > 0 ? '-' : ''
                      }}{{
                        `${promotion.promotion_value}` | numeral('0,0.00')
                      }}</span
                    >

                    <span
                      class="text-m-16"
                      :class="{
                        'text-danger':
                          !(
                            promotion.point >= 0 &&
                            promotion.promotion_value == 0
                          ) &&
                          promotion.discount_type !== 'point' &&
                          promotion.promotion_discount_type_id !== 3
                      }"
                    >
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? 'แต้ม'
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == 'percent'
                          ? '%'
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == 'amount'
                          ? 'บาท'
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == 'point'
                          ? 'แต้ม'
                          : ''
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="isCampaign && indexEdit == 1">
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in items"
                :key="index"
                @click="selectRow('addCoupon', promotion)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="text-m-16">{{ promotion.name }}</p>

                    <p v-if="promotion.code" class="text-m-14">
                      {{ promotion.code }}
                    </p>
                  </div>
                  <div class="flex-gap-1">
                    <span
                      v-if="
                        promotion.point >= 0 && promotion.promotion_value == 0
                      "
                      class="text-m-16"
                    >
                      ได้รับ {{ promotion.point | numeral('0,0') }}</span
                    >
                    <span
                      v-else-if="promotion.custom_header_discount"
                      class="text-m-16 text-danger"
                    >
                      {{ promotion.custom_header_discount > 0 ? '-' : ''
                      }}{{
                        promotion.custom_header_discount | numeral('0,0.00')
                      }}</span
                    >

                    <span v-else class="text-m-16 text-danger"
                      >{{ promotion.promotion_value > 0 ? '-' : ''
                      }}{{
                        `${promotion.promotion_value}` | numeral('0,0.00')
                      }}</span
                    >

                    <span
                      class="text-m-16"
                      :class="{
                        'text-danger':
                          !(
                            promotion.point >= 0 &&
                            promotion.promotion_value == 0
                          ) &&
                          promotion.discount_type !== 'point' &&
                          promotion.promotion_discount_type_id !== 3
                      }"
                    >
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? 'แต้ม'
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == 'percent'
                          ? '%'
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == 'amount'
                          ? 'บาท'
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == 'point'
                          ? 'แต้ม'
                          : ''
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>

            <div v-if="isLoading" class="loading-panel">
              <OtherLoading />
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import InputText from '@/components/inputs/InputText';
import { mapGetters } from 'vuex';

export default {
  name: 'SlideCustomerList',
  components: { OtherLoading, InputText },
  props: {
    headerDiscountOption: {
      type: Array,
      isRequired: true
    },
    promotionList: {
      type: Array,
      isRequired: true
    },
    itemDiscountList: {
      type: Array,
      isRequired: true
    },
    headerDiscountItems: {
      type: Array,
      isRequired: true
    },
    isCampaign: {
      type: Boolean,
      isRequired: false
    }
  },

  data() {
    return {
      indexEdit: 0,
      isLoading: false,
      showModal: false,
      filter: {
        search: '',
        datetime: '',
        branchId: '',
        page: 1,
        take: 20
      },
      now: null,
      items: [],
      listWrapper: null,
      rows: 0,

      displayPromotionList: [],
      displayItemDiscountList: [],

      tempPromotionList: [],
      tempItemDiscountList: [],
      tempCouponList: []
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: 'getDateTimeFix',
      branchId: 'getBranchId'
    }),
    isLastPage() {
      return this.items.length === this.rows;
    }
  },
  watch: {
    promotionList: {
      handler() {
        this.displayPromotionList = JSON.parse(
          JSON.stringify(this.promotionList)
        );
        this.tempPromotionList = JSON.parse(JSON.stringify(this.promotionList));
      },
      immediate: true
    },
    itemDiscountList: {
      handler() {
        this.displayItemDiscountList = JSON.parse(
          JSON.stringify(this.itemDiscountList)
        );
        this.tempItemDiscountList = JSON.parse(
          JSON.stringify(this.itemDiscountList)
        );
      },
      immediate: true
    }
  },
  created: async function () {
    this.now = this.$moment().format('YYYY-MM-DDTHH:mm:ss');
  },
  mounted() {},
  methods: {
    clearSearch() {
      this.filter.search = '';
      this.displayPromotionList = JSON.parse(
        JSON.stringify(this.tempPromotionList)
      );
      this.displayItemDiscountList = JSON.parse(
        JSON.stringify(this.tempItemDiscountList)
      );
      this.items = JSON.parse(JSON.stringify(this.tempCouponList));
    },
    handleSearch() {
      if (this.indexEdit === 0) {
        this.displayPromotionList = this.filter.search
          ? this.tempPromotionList.filter(el =>
              el.name.toLowerCase().includes(this.filter.search.toLowerCase())
            )
          : JSON.parse(JSON.stringify(this.tempPromotionList));
      } else if (this.isCampaign) {
        this.items = this.filter.search
          ? this.tempCouponList.filter(el =>
              el.name.toLowerCase().includes(this.filter.search.toLowerCase())
            )
          : JSON.parse(JSON.stringify(this.tempCouponList));
      } else {
        this.displayItemDiscountList = this.filter.search
          ? this.tempItemDiscountList.filter(el =>
              el.name.toLowerCase().includes(this.filter.search.toLowerCase())
            )
          : JSON.parse(JSON.stringify(this.tempItemDiscountList));
      }
    },
    getList: async function () {
      this.isBusy = true;

      let datetime =
        this.dateTimeFix || this.$moment().format('YYYY-MM-DDTHH:mm:ss');

      this.isLoading = true;
      await this.axios
        .get(
          `${this.$baseUrl}/coupon/get_list/${
            this.user.user_guid || ''
          }/${datetime}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.items = this.headerDiscountItems.concat(data.detail);
            this.tempCouponList = JSON.parse(JSON.stringify(this.items));
            this.rows = data.total_count;

            this.isLoading = false;
          }
        });

      this.isBusy = false;
    },
    async show(user, selecedHeaderDiscountIdList) {
      this.user = user;
      this.selectedList = [...selecedHeaderDiscountIdList];
      this.showModal = true;
      await this.getList();
    },

    hide() {
      this.showModal = false;
    },

    selectRow(action, item) {
      this.$emit(action, item);
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .nav {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;

  .nav-link {
    min-width: 107px !important;
  }
}
.customer-card {
  background-color: white;
  border-radius: 8px;
  p {
    padding: 0px;
  }
}
.customer-card:hover {
  background-color: #d1d1d1;
}

.sidebar-content {
  height: calc(100vh - 9px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .filter {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ebe5e5;
  }

  .customer-list {
    flex: 1;

    .customer-item {
      border-bottom: 1px solid #ebe5e5;
      min-height: 65px;
    }

    .loading-panel {
      height: 50px;
      div {
        height: 100%;
      }
    }
  }
}

::v-deep .b-sidebar > .b-sidebar-body {
  overflow: hidden;
}

.delete-icon {
  margin-bottom: 11px;
  margin-left: 8px;
}
</style>
