import { render, staticRenderFns } from "./ModalBillDetails.vue?vue&type=template&id=21b51687&scoped=true"
import script from "./ModalBillDetails.vue?vue&type=script&lang=js"
export * from "./ModalBillDetails.vue?vue&type=script&lang=js"
import style0 from "./ModalBillDetails.vue?vue&type=style&index=0&id=21b51687&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b51687",
  null
  
)

export default component.exports