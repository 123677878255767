<template>
  <div>
    <b-sidebar
      id="sidebar-product"
      v-model="showModal"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-product-title"
      @hidden="hide()"
      no-header
      no-close-on-backdrop
    >
      <div class="d-flex justify-content-between align-items-center p-3">
        <label class="header-text">เพิ่มสินค้า</label>
        <div class="pointer" @click="hide()">
          <b-icon icon="x-circle-fill"></b-icon>
        </div>
      </div>
      <b-tabs v-model="tabIndex" class="product-tab">
        <b-tab>
          <template #title>
            <div class="text-m-14">ทั้งหมด</div>
            <div class="text-m-14">
              {{ `(${all.rows})` }}
            </div>
          </template>
          <div class="d-flex justify-content-start align-items-end p-2 filter">
            <InputText
              v-model="all.filter.search"
              textFloat=""
              type="text"
              name="search_product"
              faIcon="search"
              @onEnter="val => handleSearch(val, 'all')"
              @onClickIcon="val => handleSearch(val, 'all')"
            />
            <div>
              <img
                src="@/assets/images/icons/bin.png"
                alt="delete"
                class="pointer delete-icon"
                center
                width="32"
                @click="handleSearch('', 'all')"
              />
            </div>
          </div>

          <div class="product-list" id="product-list-all" @scroll="loadMoreAll">
            <div
              class="p-2 my-2 bg-white pointer product-item"
              v-for="(c, index) in all.items"
              :key="index"
            >
              <div class="product-card-detail">
                <b-form-checkbox
                  v-model="productSelect"
                  :value="c.barcode"
                  @change="selectRow($event, c)"
                  class="product-detail-checkbox"
                >
                  <div>
                    <p class="text-m-16">{{ c.barcode }}</p>
                    <p class="text-r-16">{{ c.name }}</p>
                  </div>

                  <div>
                    <p class="text-m-16">
                      {{ c.price | numeral('0,0.00') }}
                    </p>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="all.isBusy" class="loading-panel">
              <OtherLoading />
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="text-m-14">สินค้า</div>
            <div class="text-m-14">
              {{ `(${booth.rows})` }}
            </div>
          </template>
          <div class="d-flex justify-content-start align-items-end p-2 filter">
            <InputText
              v-model="booth.filter.search"
              textFloat="ค้นหา"
              type="text"
              name="search_product"
              faIcon="search"
              @onEnter="val => handleSearch(val, 'booth')"
              @onClickIcon="val => handleSearch(val, 'booth')"
            />
            <div>
              <img
                src="@/assets/images/icons/bin.png"
                alt="delete"
                class="pointer delete-icon"
                center
                width="32"
                @click="handleSearch('', 'booth')"
              />
            </div>
          </div>

          <div
            class="product-list"
            id="product-list-booth"
            @scroll="loadMoreBooth"
          >
            <div
              class="p-2 my-2 bg-white pointer product-item"
              v-for="(c, index) in booth.items"
              :key="index"
            >
              <div class="product-card-detail">
                <b-form-checkbox
                  v-model="productSelect"
                  :value="c.barcode"
                  @change="selectRow($event, c)"
                  class="product-detail-checkbox"
                >
                  <div>
                    <p class="text-m-16">{{ c.barcode }}</p>
                    <p class="text-r-16">{{ c.name }}</p>
                  </div>

                  <div>
                    <p class="text-m-16">
                      {{ c.price | numeral('0,0.00') }}
                    </p>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="booth.isBusy" class="loading-panel">
              <OtherLoading />
            </div>
          </div>
        </b-tab>
        <b-tab v-if="isSpa">
          <template #title>
            <div class="text-m-14">บริการสปา</div>
            <div class="text-m-14">
              {{ `(${spa.rows})` }}
            </div>
          </template>
          <div class="d-flex justify-content-start align-items-end p-2 filter">
            <InputText
              v-model="spa.filter.search"
              textFloat="ค้นหา"
              type="text"
              name="search_product"
              faIcon="search"
              @onEnter="val => handleSearch(val, 'spa')"
              @onClickIcon="val => handleSearch(val, 'spa')"
            />
            <div>
              <img
                src="@/assets/images/icons/bin.png"
                alt="delete"
                class="pointer delete-icon"
                center
                width="32"
                @click="handleSearch('', 'spa')"
              />
            </div>
          </div>

          <div class="product-list" id="product-list-spa" @scroll="loadMoreSpa">
            <div
              class="p-2 my-2 bg-white pointer product-item"
              v-for="(c, index) in spa.items"
              :key="index"
            >
              <div class="product-card-detail">
                <b-form-checkbox
                  v-model="productSelect"
                  :value="c.barcode"
                  @change="selectRow($event, c)"
                  class="product-detail-checkbox"
                >
                  <div>
                    <p class="text-m-16">{{ c.barcode }}</p>
                    <p class="text-r-16">{{ c.name }}</p>
                  </div>

                  <div>
                    <p class="text-m-16">
                      {{ c.price | numeral('0,0.00') }}
                    </p>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="spa.isBusy" class="loading-panel">
              <OtherLoading />
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <template #footer v-if="tabIndex !== 3">
        <div class="d-flex justify-content-between w-100 my-0 p-2">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="clearProduct"
          >
            ล้าง
          </b-button>

          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="addProductList"
          >
            ตกลง
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputText from '@/components/inputs/InputText';
import OtherLoading from '@/components/loading/OtherLoading';
import Pagination from '@/components/Pagination';
import { mapGetters } from 'vuex';
import ModalNote from '@/components/customer/ModalNote';
import HeaderPanel from '@/components/HeaderPanel';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';

export default {
  components: {
    OtherLoading,
    Pagination,
    InputText,
    ModalNote,
    HeaderPanel,
    InputDatePickerFilter
  },
  props: {
    form: {
      type: Object,
      default: true
    },
    promotionList: {
      type: Array,
      default: true
    }
  },
  data() {
    return {
      productSelect: [],
      productFormSelect: [],
      isLoading: false,
      showModal: false,
      all: {
        filter: {
          search: '',
          datetime: '',
          branchId: '',
          page: 1,
          take: 20
        },
        items: [],
        isBusy: false,
        rows: 0
      },
      booth: {
        filter: {
          search: '',
          datetime: '',
          branchId: '',
          page: 1,
          take: 20
        },
        items: [],
        isBusy: false,
        rows: 0
      },
      spa: {
        filter: {
          search: '',
          datetime: '',
          branchId: '',
          page: 1,
          take: 20
        },
        items: [],
        isBusy: false,
        rows: 0
      },
      credit: {
        fields: [
          {
            key: 'created_time',
            label: 'วันที่ซื้อ',
            thClass: 'credit-column'
          },
          {
            key: 'credit_balance',
            label: 'คงเหลือ / จำนวนเครดิต (บาท)',
            thClass: 'credit-column'
          },
          {
            key: 'expired_date',
            label: 'วันที่หมดอายุ',
            thClass: 'credit-column'
          }
        ],
        form: {
          id: 1,
          net_price: 0,
          discount_price: 0,
          total_price: 0,
          expired_date: null
        },
        filter: {
          search: ''
        },
        total_credit: 0,
        items: [],
        isBusy: false,
        rows: 0
      },

      now: null,
      expireDate: null,
      tabIndex: 0,
      user: null
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: 'getDateTimeFix',
      branchId: 'getBranchId',
      isSpa: 'getSpa',
      isBooth: 'getBooth'
    })
  },
  watch: {
    'credit.form.net_price'(newValue, oldValue) {
      this.credit.form.total_price =
        parseFloat(newValue) + parseFloat(this.credit.form.discount_price);
    },
    'credit.form.discount_price'(newValue, oldValue) {
      this.credit.form.total_price =
        parseFloat(newValue) + parseFloat(this.credit.form.net_price);
    }
  },
  methods: {
    clearProduct() {
      this.productSelect = [];
      this.productFormSelect = [];
    },
    addProductList() {
      this.$emit('addProductList', this.productFormSelect);
      this.hide();
    },
    loadMoreAll() {
      const masonry = document.querySelector('#product-list-all');
      if (
        masonry.scrollTop + masonry.clientHeight >=
        masonry.scrollHeight - 20
      ) {
        if (this.all.isBusy || this.all.rows === this.all.items.length) return;
        this.all.filter.page += 1;
        this.getSearchAll();
      }
    },
    loadMoreBooth() {
      const masonry = document.querySelector('#product-list-booth');
      if (
        masonry.scrollTop + masonry.clientHeight >=
        masonry.scrollHeight - 20
      ) {
        if (this.booth.isBusy || this.booth.rows === this.booth.items.length)
          return;
        this.booth.filter.page += 1;
        this.getSearchBooth();
      }
    },
    loadMoreSpa() {
      const masonry = document.querySelector('#product-list-spa');
      if (
        masonry.scrollTop + masonry.clientHeight >=
        masonry.scrollHeight - 20
      ) {
        if (this.spa.isBusy || this.spa.rows === this.spa.items.length) return;
        this.spa.filter.page += 1;
        this.getSearchSpa();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async searchBeforeShow(user, search) {
      this.now = this.$moment().format('YYYY-MM-DDTHH:mm:ss');

      this.user = user;
      this.tabIndex = 0;
      this.all.filter.search = search;
      this.credit.form = {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: this.$moment()
          .add(2, 'years')
          .format('YYYY-MM-DDTHH:mm:ss')
      };
      this.all.filter.page = 1;
      await this.getSearchAll();
      const items = this.all.items;
      this.all.items = [];
      return items;
    },
    async show(user, search) {
      this.productSelect = JSON.parse(JSON.stringify(this.form.product)).map(
        el => el.barcode
      );
      this.productFormSelect = JSON.parse(JSON.stringify(this.form.product));

      this.now = this.$moment().format('YYYY-MM-DDTHH:mm:ss');

      this.user = user;
      this.tabIndex = 0;
      this.all.filter.search = search;
      this.credit.form = {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: this.$moment()
          .add(2, 'years')
          .format('YYYY-MM-DDTHH:mm:ss')
      };
      this.all.filter.page = 1;
      await this.getSearchAll();
      this.booth.filter.page = 1;
      await this.getSearchBooth();
      this.spa.filter.page = 1;
      await this.getSearchSpa();

      // this.productSelect = [];
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    pagination(page, key) {
      this[key].filter.page = page;
      this.searchByTab();
    },
    handleChangeTake(value, key) {
      this[key].filter.page = 1;
      this[key].filter.take = value;
      this.searchByTab();
    },
    handleSearch(search, key) {
      if (!search) this.clearProduct();
      this[key].items = [];
      this[key].filter.page = 1;
      this[key].filter.search = search;
      this.searchByTab();
    },
    searchByTab() {
      if (this.tabIndex == 0) {
        this.getSearchAll();
      } else if (this.tabIndex == 1) {
        this.getSearchBooth();
      } else if (this.tabIndex == 2) {
        this.getSearchSpa();
      }
    },
    async selectRow(val, item, key) {
      const ids = this.productFormSelect.map(el => el.barcode);
      if (item && !ids.includes(item.barcode)) this.addProduct(item);
      else {
        const index = this.productFormSelect.findIndex(
          el => el.barcode == item.barcode
        );
        this.productFormSelect.splice(index, 1);
      }
    },

    async getDiscount(index = null, productId = null) {
      await this.axios
        .get(
          `${this.$baseUrl}/promotion/get_list/1/${productId}/${
            this.user.user_guid
          }/${this.$moment().format('YYYY-MM-DD HH:mm:ss')}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            await data.detail.forEach(promotion => {
              if (!this.productFormSelect[index].sap_discount.length) {
                this.productFormSelect[index].sap_discount.push({
                  ...promotion
                });
              }
            });
          }
        });
    },

    async addProduct(product, key) {
      let productId = [];
      if (this.productFormSelect.length) {
        productId = await this.productFormSelect.map(el => el.id);
        productId = await productId.filter(function (item, pos) {
          return productId.indexOf(item) == pos;
        });
      }
      let body = {
        user_guid: this.user.user_guid,
        barcode: product.barcode,
        product_id: productId
      };
      await this.axios
        .post(`${this.$baseUrl}/product/product_lookup`, body)
        .then(async data => {
          if (data.result == 1) {
            data.detail.quantity = 1;
            data.detail.group_barcode = '';
            data.detail.item_discount = [];
            data.detail.sap_discount = [];
            const index = this.productSelect.length - 1;

            this.productFormSelect.push({ ...data.detail, index });
            await this.getDiscount(index, data.detail.id);
          } else {
            this.warningAlert(data.message);
            this.productSelect.splice(this.productSelect.length - 1, 1);
          }
        });
    },
    triggerChecked(key) {
      const ids = this.productSelect.map(el => el.barcode);
      if (key !== 'all') {
        this.all.items = this.all.items.map(prod => {
          return { ...prod, checked: ids.includes(prod.barcode) };
        });
      }

      if (key !== 'booth') {
        this.booth.items = this.booth.items.map(prod => {
          return { ...prod, checked: ids.includes(prod.barcode) };
        });
      }

      if (key !== 'spa') {
        this.spa.items = this.spa.items.map(prod => {
          return { ...prod, checked: ids.includes(prod.barcode) };
        });
      }
    },
    async getSearchAll() {
      this.all.isBusy = true;
      let body = {
        articleType: '',
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.all.filter.search,
        skip: (this.all.filter.page - 1) * this.all.filter.take,
        take: this.all.filter.take,
        spa: this.isSpa,
        booth: this.isBooth
      };
      await this.axios
        .post(`${this.$baseUrl}/product/search_product`, body)
        .then(data => {
          if (data.result == 1) {
            this.all.items = this.all.items.concat(data.detail);
            this.all.rows = data.total_count;
          }
          this.all.isBusy = false;
        });
    },
    async getSearchBooth() {
      this.booth.isBusy = true;
      let body = {
        articleType: 'ZFIN',
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.booth.filter.search,
        skip: (this.booth.filter.page - 1) * this.booth.filter.take,
        take: this.booth.filter.take,
        spa: this.isSpa,
        booth: this.isBooth
      };
      if (this.isBooth) {
        await this.axios
          .post(`${this.$baseUrl}/product/search_product`, body)
          .then(data => {
            if (data.result == 1) {
              this.booth.items = this.booth.items.concat(data.detail);
              this.booth.rows = data.total_count;
            }
            this.booth.isBusy = false;
          });
      }
    },
    async getSearchSpa() {
      this.spa.isBusy = true;
      let body = {
        articleType: 'ZPACK',
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.spa.filter.search,
        skip: (this.spa.filter.page - 1) * this.spa.filter.take,
        take: this.spa.filter.take,
        spa: this.isSpa,
        booth: this.isBooth
      };
      if (this.isSpa) {
        await this.axios
          .post(`${this.$baseUrl}/product/search_product`, body)
          .then(data => {
            if (data.result == 1) {
              this.spa.items = this.spa.items.concat(data.detail);
              this.spa.rows = data.total_count;
            }
            this.spa.isBusy = false;
          });
      }
    },
    async addCredit() {
      await this.$emit('addCredit', this.credit.form);
      await this.hide();
    },
    inputExpiredDate(val) {
      this.credit.form.expired_date = val;
    },
    editExpiredDate(item) {
      this.$refs.modalEditExpiredDate.show(item);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .nav {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;

  .nav-link {
    min-width: 107px !important;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

::v-deep .custom-control-label::before {
  top: 14px;
}

::v-deep .custom-control-label::after {
  top: 14px;
}

.delete-icon {
  margin-bottom: 3px;
  margin-left: 8px;
}

.filter {
  border-bottom: 1px solid #ebe5e5;
}

.product-list {
  flex: 1;
  height: calc(100vh - 230px);
  overflow: scroll;

  .product-card-detail {
    display: flex;
    justify-content: space-between;

    .product-detail-checkbox {
      width: 100%;
      cursor: pointer;

      ::v-deep .custom-control-label {
        display: flex !important;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .product-item {
    border-bottom: 1px solid #ebe5e5;
  }

  .loading-panel {
    height: 50px;
    div {
      height: 100%;
    }
  }
}

::v-deep .b-sidebar > .b-sidebar-body {
  overflow: hidden;
}

.w-full {
  width: 100%;
}
</style>
