<template>
  <div class="relative">
    <template v-if="isMobile">
      <MobilePanel
        :form="form"
        :user="user"
        :transaction="transaction"
        :filter="filter"
        :headerDiscountOption="headerDiscountOption"
        :promotionList="promotionList"
        :itemDiscountList="itemDiscountList"
        :selecedHeaderDiscountIdList="selecedHeaderDiscountIdList"
        :productSelected="productSelected"
        :headerDiscountItems="headerDiscountItems"
        @setUserSelect="setUserSelect"
        @handleSearchCustomer="handleSearchCustomer"
        @getUserDetail="getUserDetail(user.user_guid)"
        @addProduct="addProduct"
        @addCredit="addCredit"
        @addProductList="addProductList"
        @upProductQuantity="upProductQuantity"
        @downProductQuantity="downProductQuantity"
        @addCoupon="addCoupon"
        @deleteCoupon="deleteCoupon"
        @setSelectProduct="setSelectProduct"
        @getDiscount="getDiscount"
        @addItemDiscount="addItemDiscount"
        @addSapDiscount="addSapDiscount"
        @deleteItemDiscount="deleteItemDiscount"
        @deleteSapDiscount="deleteSapDiscount"
        @submit="submit"
      >
        <template #filter="{ handleMobileSearchCustomer, handleMobileSearch }">
          <InputText
            v-model="filter.member.search"
            type="text"
            faIcon="search"
            className="mb-2 rounded bg-second"
            placeholder="ค้นหาลูกค้า"
            @onClickIcon="handleMobileSearch"
            textField="telephone"
            @onEnter="handleMobileSearch"
          />
        </template>
        <template #form>
          <b-row class="px-2">
            <b-col cols="6">
              <InputText
                textFloat="รหัสพนักงาน"
                v-model="form.saleId"
                placeholder="รหัสพนักงาน"
                type="text"
            /></b-col>
            <b-col cols="6">
              <InputText
                textFloat="หมายเลขบิล"
                :value="form.invoiceNo"
                placeholder="หมายเลขบิล"
                type="text"
                :disabled="isFullPos == 1"
                @onKeydown="handleInvoiceNo"
              />
            </b-col>
          </b-row>
        </template>
      </MobilePanel>
    </template>
    <template v-else>
      <div class="d-flex">
        <div class="pt-1 layout-left">
          <b-row no-gutters class="px-3 head-total">
            <b-col >
              <div class="bg-secondary">
                <p>Total QTY</p>
                <p>(จำนวนสุทธิ)</p>
                <p class="points">
                  {{ transaction.quantity | numeral('0,0') }}
                </p>
              </div>
            </b-col>
            <b-col >
              <div class="bg-secondary">
                <p>Total Amount</p>
                <p>(ราคารวม)</p>
                <p class="points">
                  {{ transaction.price | numeral('0,0.00') }}
                </p>
              </div>
            </b-col>
            <b-col >
              <div class="bg-secondary">
                <p>Total Discount</p>
                <p>(ส่วนลดรวม)</p>
                <p class="points">
                  {{ transaction.discount | numeral('0,0.00') }}
                </p>
              </div>
            </b-col>
            <b-col >
              <div class="bg-secondary">
                <p>Total Net Amount</p>
                <p>(ราคาสุทธิ)</p>
                <p class="points">
                  {{ transaction.netPrice | numeral('0,0.00') }}
                </p>
              </div>
            </b-col>
            <b-col v-if="!pointSystem" >
              <div class="bg-secondary">
                <p>Branch Point</p>
                <p>(คะแนนสะสมสาขา)</p>
                <p class="points">
                  {{ transaction.center_point | numeral('0,0') }}
                </p>
              </div>
            </b-col>
            <b-col >
              <div class="bg-secondary">
                <p>Total Point</p>
                <p>(คะแนนสะสม)</p>
                <p class="points">
                  {{ transaction.sumPoint | numeral('0,0') }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="p-3">
            <b-col class="pr-0">
              <InputText
                v-model="filter.search"
                textFloat="EAN/Article"
                placeholder="EAN/Article"
                type="text"
                @onEnter="searchProduct"
              >
                <template v-slot:text>
                  <u
                    @click="searchProduct"
                    class="text-desc-message text-black font-weight-bold pointer"
                    >ค้นหา</u
                  >
                </template>
              </InputText>
            </b-col>
            <b-col class="pr-0">
              <InputText
                v-model="form.saleId"
                textFloat="Salesperson ID"
                placeholder="Salesperson ID"
                type="text"
              />
            </b-col>
            <b-col class="pr-0">
              <InputText
                v-model="form.invoiceNo"
                textFloat="POS/MIM Ref No."
                placeholder="POS/MIM Ref No."
                type="text"
                @onKeydown="handleInvoiceNo"
                :disabled="isFullPos == 1"
              />
            </b-col>
            <b-col
              cols="1"
              class="d-flex justify-content-center align-items-end"
            >
              <img
                src="@/assets/images/icons/bin.png"
                alt="delete"
                class="pointer delete-icon"
                center
                width="40"
                @click="clearPage"
              />
            </b-col>
          </b-row>
          <div class="m-3">
            <b-row class="mx-0 text-center header-products">
              <b-col cols="1" class="header-products-item">
                <p>จำนวน</p>
                <p>Qty</p>
              </b-col>
              <b-col md="4" class="header-products-item">
                <p>รายละเอียดสินค้า/โปรโมชั่น</p>
                <p>Description/Promotion</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคาต่อหน่วย</p>
                <p>Unit Price</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคารวม</p>
                <p>Total</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ส่วนลด</p>
                <p>Discount</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคาสุทธิ</p>
                <p>Net</p>
              </b-col>
            </b-row>
          </div>
          <div class="product-list-container px-3">
            <div class="item-products" v-if="form.credit">
              <b-row :class="['product-panel align-items-center mx-0 mb-2']">
                <b-col
                  cols="1"
                  class="d-flex justify-content-center align-items-center border-product py-4"
                >
                  <div class="delete-credit">
                    <font-awesome-icon
                      icon="trash-alt"
                      title="delete-btn"
                      class="pointer"
                      @click="deleteCredit"
                    />
                  </div>
                </b-col>
                <b-col md="4" class="pointer">
                  <p>วงเงินเครดิต</p>
                  <p class="product-info">
                    วงเงินที่ใช้ได้:
                    {{ form.credit.total_price | numeral('0,0.00') }} บาท
                  </p>
                </b-col>
                <b-col class="price">
                  <p>-</p>
                </b-col>
                <b-col class="price">
                  <p>{{ form.credit.total_price | numeral('0,0.00') }}</p>
                </b-col>
                <b-col class="price">
                  <p>{{ form.credit.discount | numeral('0,0.00') }}</p>
                </b-col>
                <b-col class="price">
                  <p>{{ form.credit.net_price | numeral('0,0.00') }}</p>
                </b-col>
              </b-row>
            </div>

            <div class="item-products" v-if="form.product.length > 0">
              <b-row
                v-for="(product, index) in form.product"
                :key="index"
                @click="setSelectProduct({ index: index, ...product })"
                :class="[
                  'product-panel align-items-center mx-0 mb-2 py-1',
                  { selected: productSelected.index == index }
                ]"
              >
                <b-col cols="1" class="text-center border-product py-2">
                  <p @click="downProductQuantity(index)">
                    <font-awesome-icon
                      icon="minus-circle"
                      title="down-btn"
                      class="pointer"
                    />
                  </p>
                  <p class="my-0">{{ product.quantity }}</p>
                  <p @click="upProductQuantity(index)">
                    <font-awesome-icon
                      icon="plus-circle"
                      title="up-btn"
                      class="pointer"
                    />
                  </p>
                </b-col>
                <b-col md="4" class="pointer">
                  <p>{{ product.barcode }}</p>
                  <p class="product-info">{{ product.name }}</p>
                  <p v-if="product.promotion_detail">
                    {{ product.promotion_detail.textPromotion }}
                  </p>
                </b-col>
                <b-col class="price">
                  <div
                    v-if="
                      product.promotion_detail &&
                      product.promotion_detail.normal_unit_price !=
                        product.promotion_detail.promo_unit_price
                    "
                  >
                    <p>
                      {{
                        product.promotion_detail.net_price_per_item
                          | numeral('0,0.00')
                      }}
                    </p>
                    <p class="normal-unit-price">
                      {{ product.price | numeral('0,0.00') }}
                    </p>
                  </div>
                  <p v-else>{{ product.price | numeral('0,0.00') }}</p>
                </b-col>
                <b-col class="price">
                  <p v-if="product.promotion_detail">
                    {{
                      (product.promotion_detail.promo_unit_price *
                        product.promotion_detail.q)
                        | numeral('0,0.00')
                    }}
                  </p>
                </b-col>
                <b-col class="price">
                  <p v-if="product.promotion_detail">
                    {{ product.promotion_detail.discount | numeral('0,0.00') }}
                  </p>
                </b-col>
                <b-col class="price">
                  <p v-if="product.promotion_detail">
                    {{ product.promotion_detail.net_price | numeral('0,0.00') }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <div
              v-if="!form.credit && !form.product.length"
              class="p-2 wrap-no-product"
            >
              <div class="no-product">
                ไม่มีสินค้าในตะกร้า<br />
                กรุณาสแกนบาร์โค้ดหรือค้นหาสินค้า
              </div>
            </div>
          </div>
        </div>
        <div class="layout-right">
          <b-row class="mb-2">
            <b-col>
              <div class="px-3 pt-2">
                <InputText
                  v-model="filter.member.search"
                  type="text"
                  faIcon="search"
                  className="mb-2 rounded bg-second"
                  @onKeypress="handleSearchCustomer"
                  @onClickIcon="handleSearch"
                  @selectAutoComplete="setUserSelect"
                  :optionsAutoComplete="customerList"
                  textField="telephone"
                  @onEnter="handleSearch"
                />
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">ชื่อ-สกุล</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="`${user.first_name_th} ${user.last_name_th}`"
                      type="text"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">เบอร์โทรศัพท์</p>
                  </b-col>
                  <b-col>
                    <InputText
                      v-model="user.telephone"
                      type="text"
                      name="telephone"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">หมายเลขสมาชิก</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="`${user.member_id} ${
                        user.member_level_name
                          ? '(' + user.member_level_name + ')'
                          : ''
                      }`"
                      type="text"
                      name="member_id"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">วันเกิด</p>
                  </b-col>
                  <b-col>
                    <InputDatePickerFilter
                      :value="user.birthday"
                      disabled
                      className="input-border-0"
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">คะแนนสะสมสาขา</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="user.user_guid ? user.point : ''"
                      type="text"
                      name="point"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">คะแนนสะสมทั้งหมด</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="user.user_guid ? user.center_point : ''"
                      type="text"
                      name="center_point"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">วงเงินเครดิต</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="user.user_guid ? user.credit : ''"
                      type="text"
                      name="credit"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">PDPA</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="`${user.consent_status}`"
                      type="text"
                      name="valid_user"
                      className="input-border-0"
                      isDisplay
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <div class="d-inline px-3">
            <router-link :to="`/transaction?memberId=${user.member_id}`">
              <b-button
                variant="primary-color"
                class="btn-main-custom mr-1"
                :disabled="!user.user_guid"
              >
                รายการขายย้อนหลัง
              </b-button>
            </router-link>
            <b-button
              variant="primary-color"
              class="btn-main-custom mr-1"
              :disabled="!user.user_guid"
              @click="editMember"
            >
              แก้ไขข้อมูลลูกค้า
            </b-button>
            <b-button
              target="_blank"
              :to="`redeem${
                user.user_guid ? '?telephone=' + user.telephone : ''
              }`"
              variant="primary-color bgc-secondary"
              class="btn-gift"
            >
              <font-awesome-icon icon="gift" />
            </b-button>
          </div>
          <div class="mt-1 tab-discount">
            <b-tabs v-model="indexEdit" fill>
              <b-tab :title="`โปรโมชั่น (${promotionList.length})`">
                <div
                  v-for="(promotion, i) in promotionList"
                  :key="i"
                  class="d-flex justify-content-between p-2 border-bottom"
                >
                  <b-form-checkbox-group
                    v-if="form.product.length"
                    id="checkbox-promotion"
                    name="checkbox-promotion"
                    v-model="form.product[productSelected.index].sap_discount"
                    @change="onChangeSapDiscount"
                  >
                    <b-form-checkbox
                      :value="{
                        ...promotion
                      }"
                    >
                      <div class="promotion-name">{{ promotion.name }}</div>
                    </b-form-checkbox>
                    <div
                      @click="adjustDiscount(promotion, 'promotion')"
                      class="promotion-edit"
                      v-if="promotion.custom_header == 1"
                    >
                      ปรับส่วนลด
                    </div>
                  </b-form-checkbox-group>
                  <div class="promotion-right">
                    <span class="promotion-result" v-if="IsHeaderItemDiscount">
                      <span v-if="promotion.promotion_value == 0">
                        <span v-if="promotion.point >= 0">
                          ได้รับ {{ promotion.point | numeral('0,0') }}</span
                        >
                      </span>
                      <span v-else>
                        {{ promotion.promotion_value | numeral('0,0') }}</span
                      >
                    </span>
                    <span>
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? 'แต้ม'
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == 'percent'
                          ? '%'
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == 'amount'
                          ? 'บาท'
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == 'point'
                          ? 'แต้ม'
                          : ''
                      }}
                    </span>
                    <div
                      class="promotion-point"
                      v-if="promotion.required_point > 0"
                    >
                      {{ promotion.required_point }} P
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="`ส่วนลดรายการ (${itemDiscountList.length})`">
                <div
                  v-for="(promotion, i) in itemDiscountList"
                  :key="i"
                  class="d-flex justify-content-between p-2 border-bottom"
                >
                  <b-form-checkbox-group
                    v-if="form.product.length"
                    id="checkbox-promotion-item"
                    v-model="form.product[productSelected.index].item_discount"
                    name="checkbox-promotion-item"
                    @change="onChangeItemDiscount"
                  >
                    <b-form-checkbox
                      :value="{
                        ...promotion
                      }"
                    >
                      <div class="promotion-name">{{ promotion.name }}</div>
                    </b-form-checkbox>
                    <div
                      @click="adjustDiscount(promotion, 'item')"
                      class="promotion-edit"
                      v-if="promotion.custom_header == 1"
                    >
                      ปรับส่วนลด
                    </div>
                  </b-form-checkbox-group>
                  <div class="promotion-right">
                    <span class="promotion-result" v-if="IsHeaderItemDiscount">
                      <span v-if="promotion.promotion_value == 0">
                        <span v-if="promotion.point >= 0">
                          ได้รับ {{ promotion.point | numeral('0,0') }}</span
                        >
                      </span>
                      <span v-else>
                        {{ promotion.promotion_value | numeral('0,0') }}</span
                      >
                    </span>
                    <span>
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? 'แต้ม'
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == 'percent'
                          ? '%'
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == 'amount'
                          ? 'บาท'
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == 'point'
                          ? 'แต้ม'
                          : ''
                      }}
                    </span>
                    <div
                      class="promotion-point"
                      v-if="promotion.required_point > 0"
                    >
                      {{ promotion.required_point }} P
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="`ส่วนลดท้ายบิล (${headerDiscountOption.length})`">
                <div class="wrap-nav-content">
                  <div
                    v-for="(promotion, i) in headerDiscountOption"
                    :key="i"
                    :class="[
                      'd-flex justify-content-between p-2 border-bottom'
                    ]"
                  >
                    <b-form-checkbox-group
                      id="checkbox-promotion-header"
                      v-model="form.promotion"
                      name="checkbox-promotion-header"
                      @change="handleselectPromotion"
                    >
                      <b-form-checkbox
                        :value="{
                          ...promotion
                        }"
                      >
                        <div class="promotion-name">
                          {{ promotion.name }}
                        </div>
                      </b-form-checkbox>
                      <br />
                      <b-button
                        variant="link"
                        @click="adjustDiscount(promotion, 'header')"
                        class="promotion-edit"
                        v-if="promotion.custom_header == 1"
                      >
                        ปรับส่วนลด
                      </b-button>
                    </b-form-checkbox-group>
                    <div class="promotion-right">
                      <span
                        class="promotion-result"
                        v-if="IsHeaderItemDiscount"
                      >
                        <span v-if="promotion.promotion_value == 0">
                          <span v-if="promotion.point >= 0">
                            ได้รับ {{ promotion.point | numeral('0,0') }}</span
                          >
                        </span>
                        <span v-else-if="promotion.custom_header_discount">
                          {{
                            promotion.custom_header_discount | numeral('0,0')
                          }}</span
                        >
                        <span v-else>
                          {{ promotion.promotion_value | numeral('0,0') }}</span
                        >
                      </span>
                      <span>
                        {{
                          promotion.point >= 0 && promotion.promotion_value == 0
                            ? 'แต้ม'
                            : promotion.promotion_discount_type_id == 1 ||
                              promotion.discount_type == 'percent'
                            ? '%'
                            : promotion.promotion_discount_type_id == 2 ||
                              promotion.discount_type == 'amount'
                            ? 'บาท'
                            : promotion.promotion_discount_type_id == 3 ||
                              promotion.discount_type == 'point'
                            ? 'แต้ม'
                            : ''
                        }}
                      </span>
                      <div
                        class="promotion-point"
                        v-if="promotion.required_point > 0"
                      >
                        {{ promotion.required_point }} P
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="panel-coupon p-2 mt-4">
            <InputText
              textFloat="คูปอง"
              :value="selectedCouponName"
              type="text"
              name="search"
              hasIcon
              disabled
              className="rounded"
              placeholder="ไม่มีการใช้คูปอง"
            >
              <template v-slot:icon>
                <img
                  src="@/assets/images/icons/coupon.png"
                  alt="coupon"
                  class="img-coupon"
                  center
                  @click="handleCoupon"
                />
              </template>
            </InputText>
          </div>
          <div>
            <b-button variant="submit" @click="submit"> สั่งซื้อ </b-button>
          </div>
        </div>
      </div>
    </template>
    <ModalCustomerList
      :isMobile="isMobile"
      ref="modalCustomerList"
      @setUserSelect="setUserSelect"
    />
    <ModalEditMember
      ref="modalEditMember"
      @success="getUserDetail(user.user_guid)"
    />
    <ModalProductList
      :isMobile="isMobile"
      ref="modalProductList"
      @addCredit="addCredit"
      @setSelectProduct="addProduct"
    />
    <ModalAdjustDiscount
      ref="modalAdjustDiscount"
      @update="updateHeaderDiscount"
    />
    <ModalCoupon
      ref="modalCoupon"
      :isMobile="isMobile"
      @addCoupon="addCoupon"
      @deleteCoupon="deleteCoupon"
    />
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="alertMsg"
      :text="alertText"
      @confirm="confirmUseBirthday"
    />
    <ModalAlertConfirm
      ref="modalAlertConfirmTime"
      :msg="alertMsg"
      :text="alertText"
      @confirm="submit"
    />
    <ModalBillDetails
      ref="modalBillDetails"
      :paymentChannelList="paymentChannelList"
      :isFullPos="isFullPos"
    />
    <ModalPayment
      ref="modalPayment"
      :paymentChannelList="paymentChannelList"
      @confirm="confirmPayment"
    />
    <ModalEditProduct
      ref="ModalEditProduct"
      :user="user"
      :headerDiscountOption="headerDiscountOption"
      :promotionList="promotionList"
      :itemDiscountList="itemDiscountList"
      :data="form"
      :IsHeaderItemDiscount="IsHeaderItemDiscount"
      :productSelected="productSelected"
      :promotionsBirthday="promotionsBirthday"
      :checkedBirthday="checkedBirthday"
      :branchId="branchId"
      @onOpenEditHeaderDiscountModal="openModalEditHeaderDiscount"
      @onEditProduct="editProduct"
    />
  </div>
</template>

<script>
import InputText from '@/components/inputs/InputText';
import ModalCustomerList from './components/ModalCustomerList';
import ModalEditMember from './components/ModalEditMember';
import ModalProductList from './components/ModalProductList';
import ModalAdjustDiscount from './components/ModalAdjustDiscount';
import ModalCoupon from './components/ModalCoupon';
import ModalBillDetails from './components/ModalBillDetails';
import ModalPayment from './components/ModalPayment';
import ModalAlertConfirm from '@/components/modal/alert/ModalAlertConfirm';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';
import { mapGetters } from 'vuex';
import ModalEditProduct from './components/ModalEditProduct';
import MobilePanel from './mobile/MobilePanel.vue';
import {
  required,
  requiredIf,
  minLength,
  minValue
} from 'vuelidate/lib/validators';

export default {
  components: {
    InputText,
    ModalCustomerList,
    ModalEditMember,
    ModalProductList,
    ModalAdjustDiscount,
    ModalCoupon,
    ModalBillDetails,
    ModalAlertConfirm,
    InputDatePickerFilter,
    ModalPayment,
    ModalEditProduct,
    MobilePanel
  },
  name: 'HomeDesktop',
  computed: {
    selectedCouponName() {
      let filtered = this.selectedCouponList.filter(item =>
        this.selecedHeaderDiscountIdList.includes(item.id)
      );
      filtered = filtered.map((coupon, index) => coupon.name);
      return filtered.toString();
    },
    selecedHeaderDiscountIdList() {
      let promotion = this.form.promotion;
      return promotion.map((promotion, index) => promotion.id);
    },
    promotionsBirthdayId() {
      let promotion = this.promotionsBirthday;
      return promotion.map((promotion, index) => promotion.id);
    },
    ...mapGetters(['isLoading']),
    ...mapGetters({
      mainLoading: 'getMainLoading',
      branchId: 'getBranchId',
      pointSystem: 'getPointSystem',
      invoiceLength: 'getInvoiceLength',
      isInvoiceLeadingZero: 'getIsInvoiceLeadingZero',
      dateTimeFix: 'getDateTimeFix',
      tempRegisiter: 'getTempRegisiter',
      isFullPos: 'getIsFullPos',
      allowPastCoupon: 'getAllowPastCoupon'
    })
  },
  data() {
    return {
      billDetail: null,
      filter: {
        search: '',
        saleId: '',
        invoiceNo: '',
        member: {
          search: ''
        }
      },
      transaction: {
        netPrice: 0,
        discount: 0,
        quantity: 0,
        center_point: 0,
        point: 0,
        sumPoint: 0
      },
      IsHeaderItemDiscount: true,
      form: {
        saleId: '',
        invoiceNo: '',
        product: [],
        // promotions: [],
        selectedListPromotionId: [],
        promotionCustom: [],
        promotion: [],
        credit: null,
        user_guid: ''
      },
      credit: {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: null
      },
      productSelected: {
        id: 0,
        q: 0,
        custom_price: 0,
        group_barcode: '',
        sap_discount: [],
        item_discount: [],
        index: 0
      },
      user: {
        center_point: 0,
        created_time: '',
        credit: 0,
        first_name_th: '',
        last_name_th: '',
        member_id: '',
        member_level_id: 0,
        member_level_name: '',
        point: 0,
        telephone: '',
        user_guid: '',
        user_id: 0,
        valid_user: 1,
        birthday: '',
        consent_status: '',
        sumPoint: 0
      },
      selectedCouponList: [],
      promotionList: [],
      itemDiscountList: [],
      headerDiscountOption: [],
      headerDiscountSelect: [],
      indexEdit: 2,
      checkedBirthday: false,
      alertMsg: '',
      alertText: '',
      promotionsBirthday: [],
      customerList: [],
      calculateResult: true,
      paymentChannelList: [],
      isMobile: false,
      headerDiscountItems: []
    };
  },
  validations() {
    return {
      form: {
        saleId: { required },
        invoiceNo: {
          required: requiredIf(function (item) {
            return this.isFullPos == 0;
          })
        },
        product: { required, minLength: minLength(1) }
      }
    };
  },
  watch: {
    tempRegisiter: {
      handler(newValue, oldValue) {
        let user = {
          user_guid: newValue.userGuid
        };

        if (
          !newValue.userGuid ||
          (oldValue && oldValue.userGuid === newValue.userGuid)
        )
          return;
        this.setUserSelect(user);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getPaymentChannel();
    this.getHeaderDiscount();
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async addProductList(list) {
      this.form.product = JSON.parse(JSON.stringify(list));
      if (this.promotionList.length == 0) this.indexEdit = 0;

      await this.setSelectProduct({ index: 0, ...this.form.product[0] });
      await this.getHeaderDiscount();
      await this.calculateTransaction();
    },
    openSlideEditProduct(index) {
      this.setSelectProduct({ index: index, ...this.form.product[index] });
      this.$refs.ModalEditProduct.show(JSON.stringify(this.form), index);
    },
    async openModalEditHeaderDiscount(index) {
      this.indexEdit = await index;
      this.valueEdit = await this.headerDiscountOption[index].promotion_value;
      this.modalEditHeaderDiscount = true;
    },
    onResize() {
      this.isMobile = window.innerWidth < 768;
      this.$refs.modalCustomerList.hide();
      this.$refs.modalAdjustDiscount.hide();
      this.$refs.ModalEditProduct.hide();
      this.$refs.modalCoupon.hide();
      this.$refs.modalProductList.hide();
    },
    clearPage() {
      window.location.reload();
    },
    async init() {
      this.selectedCouponList = [];
      this.promotionList = [];
      this.itemDiscountList = [];
      this.headerDiscountOption = [];
      this.indexEdit = 2;
      this.checkedBirthday = false;
      this.alertMsg = '';
      this.alertText = '';
      this.promotionsBirthday = [];
      this.calculateResult = true;

      (this.user = {
        center_point: 0,
        created_time: '',
        credit: 0,
        first_name_th: '',
        last_name_th: '',
        member_id: '',
        member_level_id: 0,
        member_level_name: '',
        point: 0,
        telephone: '',
        user_guid: '',
        user_id: 0,
        valid_user: 1,
        birthday: '',
        consent_status: '',
        sumPoint: 0
      }),
        (this.form = {
          saleId: '',
          invoiceNo: '',
          product: [],
          selectedListPromotionId: [],
          promotionCustom: [],
          promotion: [],
          credit: null,
          user_guid: ''
        });

      this.transaction = {
        netPrice: 0,
        discount: 0,
        quantity: 0,
        center_point: 0,
        point: 0,
        sumPoint: 0
      };

      this.productSelected = {
        id: 0,
        q: 0,
        custom_price: 0,
        group_barcode: '',
        sap_discount: [],
        item_discount: [],
        index: 0
      };
    },
    async editMember() {
      await this.getUserDetail(this.user.user_guid);
      let user = { ...this.user };
      user.firstname = this.user.first_name_th;
      user.lastname = this.user.last_name_th;
      user.firstname_en = this.user.first_name_en;
      user.lastname_en = this.user.last_name_en;
      user.birthday = this.user.birthday;
      user.email = this.user.email.trim();

      this.$refs.modalEditMember.show(user);
    },
    handleCoupon() {
      if (this.dateTimeFix && this.allowPastCoupon == 0) {
        this.warningAlert('สาขานี้ไม่สามารถใช้คูปองย้อนหลังได้');
      } else if (!this.user.user_guid) {
        this.warningAlert('กรุณาใส่ข้อมูลลูกค้า');
      } else if (this.form.product.length == 0) {
        this.warningAlert('กรุณาใส่ผลิตภัณฑ์ก่อน');
      } else {
        this.$refs.modalCoupon.show(
          this.user,
          this.selecedHeaderDiscountIdList
        );
      }
    },
    handleSearch() {
      this.checkedBirthday = false;
      this.customerList = [];
      this.$refs.modalCustomerList.show(this.filter.member.search);
    },
    async handleSearchCustomer() {
      this.checkedBirthday = false;
      this.customerList = await this.$refs.modalCustomerList.getCustomers(
        this.filter.member.search
      );
    },
    async setUserSelect(user) {
      this.$v.form.$reset();
      await this.init();
      await this.getUserDetail(user.user_guid);
      await this.getHeaderDiscount();
      await this.getPromotionBirthday();
    },
    async getPromotionBirthday() {
      if (this.user.user_guid) {
        let month = this.$moment(this.user.birthday).month() + 1;
        let monthNow = this.$moment().month() + 1;
        if (month == monthNow) {
          this.promotionsBirthday = await this.headerDiscountOption.filter(
            item => item.birthday == 1
          );
          if (!this.checkedBirthday && this.promotionsBirthday.length > 0) {
            this.checkedBirthday = true;
            this.alertMsg = 'โปรโมชั่นสำหรับวันเกิด';
            this.alertText =
              'มีโปรโมชั่นสำหรับวันเกิด ยืนยันที่จะใช้โปรโมชั่นหรือไม่';
            this.$refs.modalAlertConfirm.show();
          }
        }
      }
    },
    async confirmUseBirthday() {
      this.form.promotion = [];
      let promotion = this.promotionsBirthday[0];
      await this.form.promotion.push({
        ...promotion
      });
    },
    async getPaymentChannel() {
      await this.axios
        .get(`${this.$baseUrl}/Transaction/GetPaymentChannel`)
        .then(async data => {
          if (data.result == 1) {
            this.paymentChannelList = data.detail;
          }
        });
    },
    async getUserDetail(userGuid) {
      await this.axios
        .get(
          `${this.$baseUrl}/customer/user_lookup_byid/${userGuid}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.filter.member.search = '';
            this.user = data.detail;
            if (this.user.birthday == '01/01/0001 00:00:00')
              this.user.birthday = null;
            else
              this.user.birthday = this.$moment(
                this.user.birthday,
                'MM/DD/YYYY HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss');
            this.user.sumPoint = data.detail.center_point + data.detail.point;
          }
        });
    },
    async getDiscount(type, index = null, productId = null) {
      await this.axios
        .get(
          `${this.$baseUrl}/promotion/get_list/${type}/${
            productId || this.productSelected.id
          }/${this.user.user_guid}/${this.$moment().format(
            'YYYY-MM-DD HH:mm:ss'
          )}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            if (type == 1) {
              this.promotionList = data.detail;
              if (!index) return;
              await this.promotionList.forEach(promotion => {
                if (!this.form.product[index].sap_discount.length) {
                  this.form.product[index].sap_discount.push({
                    ...promotion
                  });
                }
              });
              if (this.promotionList.length) this.indexEdit = 0;
            } else {
              this.itemDiscountList = data.detail;
            }
          }
        });
    },
    async getHeaderDiscount() {
      await this.axios
        .get(
          `${this.$baseUrl}/promotion/get_header_discount/${
            this.user.user_guid || ' '
          }/${this.$moment().format('YYYY-MM-DD HH:mm:ss')}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.headerDiscountItems = JSON.parse(JSON.stringify(data.detail));
            this.headerDiscountOption = data.detail;
          }
        });
    },
    onChangeSapDiscount() {
      if (this.form.product.length == 0) {
        this.form.product[this.productSelected.index].sap_discount = [];
        this.warningAlert('กรุณาใส่ผลิตภัณฑ์ก่อน');
      } else {
        this.calculateTransaction();
      }
    },
    onChangeItemDiscount() {
      if (this.form.product.length == 0) {
        this.form.product[this.productSelected.index].item_discount = [];
        this.warningAlert('กรุณาใส่ผลิตภัณฑ์ก่อน');
      } else {
        this.calculateTransaction();
      }
    },
    async searchProduct() {
      if (this.user.user_guid) {
        if (this.filter.search.length) {
          const list = await this.$refs.modalProductList.searchBeforeShow(
            this.user,
            this.filter.search
          );
          if (list.length) {
            this.addProduct(list[0]);
          } else {
            this.$refs.modalProductList.show(this.user, this.filter.search);
          }
        } else {
          this.$refs.modalProductList.show(this.user, this.filter.search);
        }
      } else {
        this.warningAlert('กรุณาใส่ข้อมูลลูกค้า');
      }
    },
    async setSelectProduct(product) {
      this.productSelected = product;
      await this.getDiscount(1, product.index);
      await this.getDiscount(2);
    },
    async addCredit(credit) {
      if (this.form.credit) {
        this.form.credit.net_price =
          parseFloat(this.form.credit.net_price) + parseFloat(credit.net_price);
        this.form.credit.discount_price =
          parseFloat(this.form.credit.discount_price) +
          parseFloat(credit.discount_price);
        this.form.credit.total_price =
          parseFloat(this.form.credit.total_price) +
          parseFloat(credit.total_price);
        this.form.credit.expired_date = credit.expired_date;
      } else {
        this.form.credit = credit;
      }
      this.calculateTransaction();
    },
    deleteCredit() {
      this.form.credit = null;

      this.calculateTransaction();
    },
    async addProduct(product, key) {
      let productId = [];
      if (this.form.product.length) {
        productId = await this.form.product.map(el => el.id);
        productId = await productId.filter(function (item, pos) {
          return productId.indexOf(item) == pos;
        });
      }
      let body = {
        user_guid: this.user.user_guid,
        barcode: product.barcode,
        product_id: productId
      };
      await this.axios
        .post(`${this.$baseUrl}/product/product_lookup`, body)
        .then(async data => {
          if (data.result == 1) {
            this.filter.search = '';
            data.detail.quantity = 1;
            data.detail.group_barcode = '';
            data.detail.sap_discount = [];
            data.detail.item_discount = [];

            await this.form.product.push(data.detail);
            let index = this.form.product.length - 1;
            await this.setSelectProduct({ ...data.detail, index: index });

            if (this.promotionList.length == 0) this.indexEdit = 2;
            await this.getHeaderDiscount();

            await this.calculateTransaction();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    async adjustDiscount(item, type) {
      item.custom_header_discount =
        item.custom_header_discount || item.promotion_value;
      this.$refs.modalAdjustDiscount.show(item, type);
    },
    async updateHeaderDiscount(promotion, type) {
      if (type == 'promotion') {
        let filtered = await this.form.product[
          this.productSelected.index
        ].sap_discount.filter(item => item.id != promotion.id);
        await filtered.push({
          ...promotion
        });
        this.form.product[this.productSelected.index].sap_discount = filtered;
      } else if (type == 'item') {
        let filtered = await this.form.product[
          this.productSelected.index
        ].item_discount.filter(item => item.id != promotion.id);
        await filtered.push({
          ...promotion
        });
        this.form.product[this.productSelected.index].item_discount = filtered;
      } else if (type == 'header') {
        let filtered = await this.form.promotion.filter(
          item => item.id != promotion.id
        );
        await filtered.push({
          ...promotion
        });
        this.form.promotion = filtered;

        await this.headerDiscountOption.forEach(element => {
          if (promotion.id == element.id) {
            element.custom_header_discount = promotion.custom_header_discount;
          }
        });
      }
      this.calculateTransaction();
    },
    async downProductQuantity(index) {
      if (this.form.product[index].quantity > 1) {
        this.form.product[index].quantity -= 1;
      } else {
        this.form.product.splice(index, 1);
      }
      if (this.form.product.length == 0) {
        this.productSelected = {
          id: 0,
          q: 0,
          custom_price: 0,
          group_barcode: '',
          sap_discount: [],
          item_discount: [],
          index: 0
        };
      }
      this.calculateTransaction();
    },
    async upProductQuantity(index) {
      this.form.product[index].quantity += 1;

      this.calculateTransaction();
    },
    async checkBirthdayPromotion() {
      let arr1 = this.selecedHeaderDiscountIdList;
      let arr2 = this.promotionsBirthdayId;
      var ret = [];
      arr1.sort();
      arr2.sort();
      for (var i = 0; i < arr1.length; i += 1) {
        if (arr2.indexOf(arr1[i]) > -1) {
          ret.push(arr1[i]);
        }
      }
      if (ret.length) {
        this.warningAlert('โปรโมชั่นวันเกิดไม่สามารถร่วมกับโปรโมชั่นอื่นได้');
        this.form.promotion = await this.form.promotion.filter(
          promotion => ret[0] == promotion.id
        );
      }
    },
    async calculateTransaction() {
      let product = this.form.product.map((prod, index) => {
        return {
          id: prod.id,
          q: prod.quantity,
          custom_price: prod.price,
          group_barcode: '',
          sap_discount: prod.sap_discount,
          item_discount: prod.item_discount
        };
      });
      let body = {
        price: [],
        promotion: this.form.promotion,
        product: product,
        credit: this.form.credit,
        customer_id: this.user.user_guid,
        user_id: this.userGuid,
        user_guid: this.userGuid,
        debug: 0,
        branch_id: this.branchId,
        t_datetime:
          this.dateTimeFix || this.$moment().format('YYYY-MM-DD HH:mm:ss')
      };
      await this.axios
        .post(`${this.$baseUrl}/transaction/calculate_transaction_data`, body)
        .then(async data => {
          if (
            (data.result == 1 && !data.error_message) ||
            (!body.product.length && !data.error_message) ||
            (!body.product.length && this.checkedBirthday)
          ) {
            this.calculateResult = true;

            await this.form.product.forEach(async (product, i) => {
              let currentProductInfo = data.detail[i];
              let textPromotion = '';
              let productPromotions = [];
              if (currentProductInfo.header_discount != null) {
                productPromotions.push(currentProductInfo.header_discount);
              }
              if (currentProductInfo.item_discount != null) {
                if (currentProductInfo.item_discount.length > 0) {
                  productPromotions.push(currentProductInfo.item_discount);
                }
              }
              if (currentProductInfo.sap_discount != null) {
                if (currentProductInfo.sap_discount.length > 0) {
                  productPromotions.push(currentProductInfo.sap_discount);
                }
              }

              await productPromotions.forEach(async (item, i) => {
                await productPromotions[i].forEach((item_i, j) => {
                  if (textPromotion != '') {
                    textPromotion += ', ';
                  }
                  textPromotion += productPromotions[i][j].name;
                });
              });

              if (textPromotion != '') {
                currentProductInfo.textPromotion = `โปรโมชั่น: ${textPromotion}`;
              } else currentProductInfo.textPromotion = `โปรโมชั่น: -`;

              this.form.product[i].promotion_detail = currentProductInfo;
            });
            // this.form.promotions = data.detail;
            if (data.price) {
              this.transaction = {
                price: data.price.price_with_no_discount || 0,
                netPrice: data.price.price || 0,
                discount: data.price.discount || 0,
                quantity: data.quantity || 0,
                point: data.center_point + data.point
              };
            } else {
              this.transaction = {
                price: 0,
                netPrice: 0,
                discount: 0,
                quantity: 0,
                point: 0
              };
            }
          } else {
            this.warningAlert(data.error_message);
            this.calculateResult = false;

            if (data.detail) {
              //sap_discount
              await data.detail.forEach(async (product, i) => {
                this.form.product[i].sap_discount = [];
                await product.sap_discount.forEach(promotion => {
                  this.form.product[i].sap_discount.push({
                    ...promotion
                  });
                });
              });

              //item_discount
              await data.detail.forEach(async (product, i) => {
                this.form.product[i].item_discount = [];
                await product.item_discount.forEach(promotion => {
                  this.form.product[i].item_discount.push({
                    ...promotion
                  });
                });
              });
            }

            // header_discount
            if (data.promotion) {
              this.form.promotion = [];
              await data.promotion.forEach(promotion => {
                this.form.promotion.push({
                  ...promotion
                });
              });
            }

            this.transaction = {
              price: 0,
              netPrice: 0,
              discount: 0,
              quantity: 0,
              point: 0
            };
          }
        });
    },
    async submit() {
      if (!this.user.user_guid) {
        this.warningAlert('กรุณาใส่ข้อมูลลูกค้า');
        return;
      } else {
        this.$v.form.$touch();
        if (this.$v.form.saleId.$error) {
          this.warningAlert('กรุณาใส่ Salesperson ID');
          return;
        } else if (this.form.product.length == 0) {
          this.warningAlert('กรุณาใส่ผลิตภัณฑ์ก่อน');
          return;
        } else if (this.$v.form.invoiceNo.$error && this.isFullPos == 0) {
          this.warningAlert('กรุณาใส่ Invoice No.');
          return;
        }
      }

      if (this.dateTimeFix) {
        this.alertMsg = 'ทำรายการย้อนหลัง';
        this.alertText =
          'ตอนนี้กำลังทำรายการย้อนหลัง ยืนยันที่จะทำรายการหรือไม่';
        this.$refs.modalAlertConfirmTime.show();
      }
      if (this.isFullPos == 0) {
        await this.submitTransaction();
      } else {
        this.$refs.modalPayment.show(this.user, this.form, this.transaction);
      }
    },
    async confirmPayment(payment) {
      await this.submitTransaction(payment);
    },
    async submitTransaction(payment = null) {
      let product = this.form.product.map((prod, index) => {
        return {
          id: prod.id,
          q: prod.quantity,
          group_barcode: '',
          sap_discount: prod.sap_discount || null,
          item_discount: prod.item_discount || null
        };
      });

      let credit = null;
      if (this.form.credit) {
        credit = { ...this.form.credit };
        credit.expired_date = this.$moment(credit.expired_date)
          .add(543, 'years')
          .format('DD/MM/YYYY');
      }
      let body = {
        price: [],
        promotion: this.form.promotion,
        product: product,
        credit: credit,
        user_guid: this.user.user_guid,
        debug: 0,
        branch_id: this.branchId,
        t_datetime:
          this.dateTimeFix || this.$moment().format('YYYY-MM-DD HH:mm:ss'),
        sales_id: this.form.saleId,
        invoice_no: this.form.invoiceNo,
        is_fullpos: this.isFullPos,
        user_get_point: '1',
        new_customer: '0',
        tourist: '0',
        partner: '0',
        partner_detail: '',
        zero_point: '0',
        payment_channel_id: payment ? payment.id : 1,
        payment_amount: payment ? payment.amount : 0
      };
      this.$v.form.$reset();
      await this.$store.dispatch('setMainLoading', true);
      await this.axios
        .post(`${this.$baseUrl}/transaction/submit_transaction_data`, body)
        .then(async data => {
          await this.$store.dispatch('setMainLoading', false);
          if (data.result == 1) {
            await this.$refs.modalBillDetails.show(data, this.user.user_guid);
            this.filter.member.search = '';
            await this.init();
            await this.getHeaderDiscount();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    handleInvoiceNo: function (evt) {
      evt = evt ? evt : window.event;
      if (evt.which === 32) evt.preventDefault();
      else {
        if (this.invoiceLength) {
          evt = evt ? evt : window.event;
          var charCode = evt.which ? evt.which : evt.keyCode;
          if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            (charCode > 105 || charCode < 96)
          ) {
            evt.preventDefault();
          } else {
            let value = evt.target.value;
            let valueLength = value.length;
            let isnum = /^\d+$/.test(parseInt(evt.key));
            let key = isnum ? evt.key : '';
            let repeatStr = '';
            if (evt.keyCode === 8) {
              value = value.substring(0, value.length - 1);
              if (this.isInvoiceLeadingZero)
                repeatStr = '0'.repeat(this.invoiceLength - valueLength + 1);
            } else {
              if (this.isInvoiceLeadingZero)
                repeatStr =
                  valueLength < this.invoiceLength
                    ? '0'.repeat(this.invoiceLength - valueLength - 1)
                    : '';
            }

            value = `${repeatStr}${value}${key}`;
            value = value.slice(this.invoiceLength * -1);
            this.form.invoiceNo = value;
            evt.preventDefault();
          }
        } else {
          let thai_text =
            'ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ';
          if (thai_text.indexOf(evt.key) >= 0) {
            evt.preventDefault();
          }
        }
      }
    },
    async addCoupon(coupon, addHeader = true) {
      if (!this.selecedHeaderDiscountIdList.includes(coupon.id)) {
        this.selectedCouponList.push(coupon);
        if (addHeader) await this.headerDiscountOption.push(coupon);
        await this.form.promotion.push({
          ...coupon
        });
      }
      await this.calculateTransaction();
    },
    async addItemDiscount(promotion) {
      const ids = this.form.product[
        this.productSelected.index
      ].item_discount.map(el => el.id);
      if (!ids.includes(promotion.id)) {
        this.form.product[this.productSelected.index].item_discount.push({
          ...promotion
        });
        await this.calculateTransaction();
      }
    },
    async addSapDiscount(promotion) {
      const ids = this.form.product[
        this.productSelected.index
      ].sap_discount.map(el => el.id);
      if (!ids.includes(promotion.id)) {
        this.form.product[this.productSelected.index].sap_discount.push({
          ...promotion
        });
        await this.calculateTransaction();
      }
    },
    deleteSapDiscount(index) {
      this.form.product[this.productSelected.index].sap_discount.splice(
        index,
        1
      );
      this.calculateTransaction();
    },
    deleteItemDiscount(index) {
      this.form.product[this.productSelected.index].item_discount.splice(
        index,
        1
      );
      this.calculateTransaction();
    },
    async deleteCoupon(coupon) {
      this.form.promotion = await this.form.promotion.filter(
        promotion => promotion.id != coupon.id
      );
      this.headerDiscountOption = await this.headerDiscountOption.filter(
        promotion => promotion.id != coupon.id
      );
      this.selectedCouponList = await this.selectedCouponList.filter(
        coupon => coupon.id != coupon.id
      );
      await this.calculateTransaction();
    },
    async handleselectPromotion(e) {
      if (this.form.product.length == 0) {
        this.form.promotion = [];
        this.warningAlert('กรุณาใส่ผลิตภัณฑ์ก่อน');
      } else {
        await this.checkBirthdayPromotion();
        await this.calculateTransaction();
      }
    },
    editProduct(detail) {
      this.form = detail;
    },
    selectDiscount(list) {
      this.headerDiscountSelect = list.id;
      this.form.promotion.headerDiscount = list.detail;
    }
  }
};
</script>

<style lang="scss" scoped>
.head-total {
  p {
    text-align: center;
    color: var(--primary-color) !important;
    font-size: 14px;
    font-weight: 600;
  }

  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .col-sm-6 {
    min-width: 133px;
    flex: 1;
    max-width: 100%;
  }
}

.delete-icon {
  width: 40px;
}

.layout-left {
  width: calc(100% - 400px);
  height: calc(100vh - 46px);

  .product-list-container {
    overflow: auto;
    height: calc(100% - 46px - 89px - 87px - 40px);
    position: relative;

    .wrap-no-product {
      width: 100%;
      height: 100%;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;

      .no-product {
        text-align: center;
        font-size: 20px;
        padding: 15px;
        background-color: #f7f7f7;
        border-radius: 10px;
        color: #dcdcdc;
        width: 450px;
      }
    }
  }

  .head-total {
    .col:first-of-type {
      padding-left: 0;
    }

    .col {
      padding: 0 5px;
    }

    .col:last-of-type {
      padding-right: 0;
    }

    .bg-secondary {
      padding: 15px 0;
      background: var(--secondary-color) !important;
    }
  }

  .header-products {
    font-size: 14px;
    font-weight: bold;
    gap: 4px;

    .header-products-item {
      padding: 0px;
    }
  }

  .product-panel {
    background-color: rgb(244, 244, 244) !important;

    &.selected {
      background-color: var(--secondary-color) !important;
    }
  }

  .item-products {
    color: #707070;
    font-size: 16px;
    border-radius: 8px;

    svg {
      color: #000;
    }

    .price {
      font-size: 14px;
      text-align: center;
    }

    .product-info {
      font-size: 12px;
    }

    .normal-unit-price {
      text-decoration: line-through;
    }
  }

  .delete-credit {
    background-color: #000 !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 4px;
    text-align: center;

    svg {
      color: white;
      font-size: 16px;
    }
  }
}

::v-deep .layout-right {
  width: 400px;
  background: #f7f7f7;
  height: calc(100vh - 112px);
  position: relative;

  p.text-title {
    font-size: 14px;
    font-weight: bold;
  }

  .bg-second .input-custom .has-icon input {
    background: var(--secondary-color) !important;
  }

  .btn-gift {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px none #f7f7f7;
    background: white;
    text-align: center;
    padding: 8px 0;

    svg {
      color: var(--primary-color) !important;
      font-size: 22px;
    }
  }

  .btn-main-custom {
    border-radius: 20px;
    height: 40px;
    width: calc((100% - 40px - 2.5rem) / 2);
  }

  .tab-discount {
    height: calc(100% - 332px - 52px - 91px - 40px);
    position: relative;

    .wrap-nav-content {
      height: calc(100vh - 650px);
      overflow-y: auto;

      .disabled {
        opacity: 0.7 !important;
      }
    }

    .nav-tabs .nav-link.active {
      border-color: var(--primary-color) !important;
      color: var(--primary-color) !important;
      background: white !important;
    }

    .nav-tabs .nav-link {
      border-bottom: 3px solid #dedede !important;
      background: #dedede !important;
      font-weight: 600;
    }

    .promotion-name {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      margin-top: 4px;
    }

    .promotion-edit {
      font-size: 12px;
      cursor: pointer;
      margin: 0;
      margin-left: 25px;
      text-decoration: underline;
      padding: 0 !important;
      color: #333 !important;
    }

    .promotion-right {
      font-weight: bold;
      color: var(--primary-color);
      text-align: right;

      .promotion-result {
        font-size: 16px;
      }

      .promotion-point {
        font-size: 10px;
      }
    }
  }

  .btn-submit {
    border-radius: 0px;
    width: 100%;
    height: 40px;
    font-size: 20px;
    line-height: 20px;
    background: var(--primary-color) !important;
    color: white !important;
  }

  .img-coupon {
    cursor: pointer;
    width: 32px;
    margin-left: 10px;
  }

  .panel-coupon {
    .input-custom .has-icon input {
      background: #cbcbcb;
      color: white !important;
    }
  }

  .bgc-secondary {
    background: var(--secondary-color) !important;
  }
}

.relative {
  position: relative;
}

::v-deep .detail-input-panel {
  padding-top: 85px;
  background-color: #f7f7f7;
}

@media (max-width: 991px) {
  ::v-deep .layout-right {
    padding-top: 16px !important;
  }
}


@media (max-width: 1200px) {
  .head-total {
    p {
      font-size: 10px;
    }
  }

  .delete-icon {
    width: 30px;
  }
}
</style>
